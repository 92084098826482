// import 'leaflet/dist/leaflet.css';
// import 'leaflet-markercluster/MarkerCluster.css';
// import 'leaflet-markercluster/MarkerCluster.Default.css';
// import L from 'leaflet';
// import 'leaflet-markercluster';
import $ from 'jquery';


var backButtonEvent = new Event('backbutton');

type Jardin = {
    id: number;
    name: string;
    lat: number;
    lng: number;
    h5p_id: null | number;
    marker: L.Marker;
    element: HTMLElement;
    img: string;
    videoUrl: null | string;
    location: string;
    altitude: number;
    nbGardeners: number;
    plants_nb: number;
    species_nb: number;
    description: string;
    classes: string;
    speciesList: string[];
    creationDate: Date;
    referent: string;
}

type JardidebAppOptions = {
    HTMLSelectors: {
        fadeSelector: string,
        videoModalSelector: string,
        videoSelector: string,
        mapSelector: string,
        gardenResultsSelector: string,
        searchBarSelector: string,
        h5pContainerSelector: string,
    },
    gardenElements: HTMLElement[],
    HTMLButtonsSelectors: {
        closeGardenButtonSelector: string,
        openGardenDetailsButtonSelector: string,
    }

}

export function convertToJardin(data: any) {
    return data as Jardin;
}

enum AppState {
    MAP,
    FOCUSED,
    VIDEO,
    H5P
}

/*
* H5P Manager
* This class manages one h5p elements in the page and is responsible for opening and closing the h5p elements
* It listens to the domChanged event from the H5P externalDispatcher
* It listens to the onH5POpened event from the document
*/
class HP5Manager {
    public H5PContainer?: HTMLElement | null;
    private MainContainer: HTMLElement | null;
    private H5PReady: boolean;
    private H5P: any;
    public h5pClasses = ["absolute", "top-0", "bottom-0", "left-0", "right-0", "backdrop-blur-md", "bg-white/30", "z-[4999]", "min-w-full", "min-h-full", "items-center", "justify-center"]
    public H5P_ID;
    private H5PMinigames: HP5MinigameManager[] = [];

    constructor(mainSelector: string, H5P_ID: number) {
        this.MainContainer = document.querySelector(mainSelector);
        this.H5PReady = false;
        this.H5P_ID = H5P_ID;
        // Load the h5p
        this.loadH5PScene(H5P_ID);
        this.H5P = H5P;
        console.log("Loading H5P : " + H5P_ID);
        this.events();
    }
    events() {
        var self = this;
        this.H5P.externalDispatcher.on('domChanged', self.H5PDomChanged);
    }

    openMinigameNote(event: any) {
        const self = this;
        if (event.data.library === "H5P.AdvancedText") {
            var textElem = event.data.$target.context;
            // Get the text only without trailing spaces
            var text = textElem.textContent.trim();
            if (text.includes("{H5PLOAD}")) {
                // Change the text to a button with a link
                var button = document.createElement("button");
                button.classList.add("bg-blue-500", "hover:bg-blue-700", "text-white", "font-bold", "py-2", "px-4", "rounded");
                // Get the id that is after the {H5PLOAD} tag
                var h5pID = text.split("{H5PLOAD}")[1];
                button.innerHTML = "Ouvrir le minijeu";
                button.onclick = function () {
                    if (!self.H5PContainer) { return; }
                    // New H5P Manager 
                    var close_button = event.data.$target.context.parentElement.parentElement.querySelector(".close-button-wrapper");
                    var miniGameH5P = new HP5MinigameManager("#" + self.MainContainer.id, h5pID, close_button);
                    self.H5PMinigames.push(miniGameH5P);
                    // Wait until an ifram with "h5p-iframe-" + h5pID is created
                    var interval = setInterval(function () {
                        var iframe = document.querySelector("#h5p-iframe-" + h5pID);
                        if (iframe) {
                            clearInterval(interval);
                            miniGameH5P.showH5P();
                        }
                    }, 500);
                    // pendingH5P = h5pID;
                    // openMiniGame = h5pID;
                }
                textElem.innerHTML = textElem.innerHTML.replace("{H5PLOAD}" + h5pID, "");
                textElem.appendChild(button);
            }
        }
    }


    H5PDomChanged(event: any) {
        if (event.data.library === "H5P.AdvancedText") {
            // @ts-ignore
            window.app.h5pManager.openMinigameNote(event);
        }
    }


    loadH5PScene(id: Number) {
        let self = this;
        // Check if the div already exists
        var H5PContainer = document.querySelector("#h5p-" + id) as HTMLElement | null;
        if (H5PContainer) {
            this.H5PContainer = H5PContainer;
            return;
        }
        this.H5PContainer = document.createElement("div");
        this.H5PContainer.id = "h5p-" + id;
        this.H5PContainer.classList.add(...this.h5pClasses);
        this.H5PContainer.hidden = true;
        this.MainContainer?.appendChild(this.H5PContainer);
        console.log(this.MainContainer)
        this.lazyLoadH5P(id, (response) => {
            // Remove the last char of the response
            response = response.slice(0, -1);
            self.H5PContainer.innerHTML = response;
            var script = self.H5PContainer.querySelector("script")?.innerText;
            eval(script || "");
            // @ts-ignore
            H5P.init(self.H5PContainer);
        });
    }

    lazyLoadH5P(id: Number, callback: (response: string) => void) {
        // Ajax call to get the h5p content
        $.ajax({
            // @ts-ignore
            url: ajax_object.ajax_url,
            type: "POST",
            data: {
                action: "json_render_h5p_shortcode",
                h5p_id: id
            },
            success: function (response) {
                return callback(response);
            },
            error: function (error) {
                console.log("Error while loading h5p content");
                console.log(error);
            }
        });
    }

    showH5PAfterLoad(iframe: HTMLElement) {
        var self = this;
        // Add a back button
        var backButton = createBackButton();
        backButton.classList.add("absolute", "top-[40px]", "left-0", "m-5");
        this.H5PContainer?.appendChild(backButton);
        backButton.onclick = function () {
            // Hide the h5p
            self.H5PContainer.hidden = true;
        }
        this.H5PContainer.hidden = false;
        // Resize the h5p
        window.dispatchEvent(new Event('resize'));
        // Resize
        iframe.style.width = "100%";
        iframe.style.height = "100%";
    }

    showH5P() {
        var self = this;
        var iframe = this.H5PContainer?.querySelector("iframe");
        // Wait until iframe
        var interval = setInterval(function () {
            var iframe = self.H5PContainer?.querySelector("iframe");
            if (iframe) {
                clearInterval(interval);
                self.showH5PAfterLoad(iframe);
            }
        }, 300);

        // console.log(type);
        // if (type === "MiniGame") {
        //     // Scale the iframe
        //     iframe.classList.add("h5p-unscale");
        //     // Center the iframe
        //     iframe.style.top = "10vh";
        //     iframe.style.left = "12vw";
        //     iframe.style.position = "relative";
        // }
    }

}

// Child class of HP5Manager
export class HP5MinigameManager extends HP5Manager {
    public h5pClasses = ["absolute", "top-0", "bottom-0", "left-0", "right-0", "backdrop-blur-md", "bg-white/80", "z-[5000]", "w-auto", "h-auto"];

    constructor(mainSelector: string, H5P_ID: number, closeButton: HTMLElement | null = null) {
        super(mainSelector, H5P_ID);
        if (closeButton) {
            closeButton.click();
        }
    }

    showH5P(): void {
        console.log(this.H5PContainer)
        super.showH5P();
        var iframe = this.H5PContainer?.querySelector("iframe");
        if (!iframe) { return; }
        // Scale the iframe
        iframe.classList.add("h5p-unscale");
        // Center the iframe
        iframe.style.top = "10vh";
        iframe.style.left = "12vw";
        iframe.style.position = "relative";
    }

}


export class JardidebApp {
    public map: JardiDebMap;
    public h5pManager?: HP5Manager;
    public jardins: Jardin[];
    public mapServer: string;
    public options: JardidebAppOptions;
    private selectedJardin: Jardin | null;
    private state: AppState;
    private searchResultsNumber: HTMLElement | null;
    private blackFadeState: string;


    constructor(options = {} as JardidebAppOptions) {
        const { fadeSelector, videoModalSelector, videoSelector, mapSelector, gardenResultsSelector, searchBarSelector } = options.HTMLSelectors;
        const { closeGardenButtonSelector, openGardenDetailsButtonSelector } = options.HTMLButtonsSelectors;
        this.jardins = [];
        this.selectedJardin = null;
        this.mapServer = "";
        this.state = AppState.MAP;
        this.searchResultsNumber = null;
        this.map = new JardiDebMap(-21.1156, 55.6018, 10, 14, document.querySelector(mapSelector) as HTMLElement);
        this.blackFadeState = "out";
        this.options = options;

    }

    get displayedGardens() {
        return this.displayedGardens;
    }

    set displayedGardens(value: Jardin[]) {
        if (value === null) {
            return
        }
        this.searchResultsNumber!.innerHTML = value.length + " résultats";
        // Set display non to all the gardens
        this.jardins.forEach(garden => {
            if (value.includes(garden)) {
                if (!garden.element) { return; }
                garden.element.style.display = "flex";
                this.map.showMarker(garden.marker);
            } else {
                garden.element!.style.display = "none";
                this.map.removeMarker(garden.marker);
            }
        });
    }

    async init() {
        const self = this;
        await this.map.initialize(this.mapServer);
        this.searchResultsNumber = document.getElementById("garden-results");
        // Add the garden elements
        this.jardins.forEach(garden => {
            garden.marker = self.map.addMarker(garden.lat, garden.lng, garden.name, function () {
                self.selectJardin(garden.id)

            });
            // Show the name of the garden on top of the marker
            garden.element = document.getElementById("garden-" + garden.id) as HTMLElement;
        });
        this.displayedGardens = this.jardins;
        this.events();
    }

    isDisplayedGarden(event: Event | null, garden: Jardin) {
        var searchBar = document.querySelector(this.options.HTMLSelectors.searchBarSelector) as HTMLInputElement;
        var search = searchBar?.value.toLowerCase();
        if (event) {
            search = (event.target as HTMLInputElement)?.value.toLowerCase();
        }
        return garden.name.toLowerCase().includes(search) && this.map.map?.getBounds().contains(garden.marker.getLatLng());
    }

    async events() {
        const self = this;
        // Fade
        var fadeElement = document.querySelector(self.options.HTMLSelectors.fadeSelector);
        fadeElement?.addEventListener("transitionend", function () {
            if (fadeElement?.getAttribute("data-fade") === "out") {
                var onBlackFadeOutEvent = new Event("onBlackFadeOut");
                document.dispatchEvent(onBlackFadeOutEvent);
                self.blackFadeState = "out";
            } else if (fadeElement?.getAttribute("data-fade") === "in") {
                var onBlackFadeInEvent = new Event("onBlackFadeIn");
                document.dispatchEvent(onBlackFadeInEvent);
                self.blackFadeState = "in";
            }
        });

        // Video modal
        var videoModal = document.querySelector(self.options.HTMLSelectors.videoModalSelector);
        videoModal?.addEventListener("transitionend", function () {
            if (videoModal?.getAttribute("data-fade") === "out") {
                var onVideoFadeOutEvent = new Event("onVideoFadeOut");
                document.dispatchEvent(onVideoFadeOutEvent);
            } else if (videoModal?.getAttribute("data-fade") === "in") {
                var onVideoFadeInEvent = new Event("onVideoFadeIn");
                document.dispatchEvent(onVideoFadeInEvent);
            }
        });

        var searchBar = document.querySelector(self.options.HTMLSelectors.searchBarSelector) as HTMLInputElement;
        searchBar?.addEventListener("keyup", function (event: KeyboardEvent) {
            var search = (event.target as HTMLInputElement)?.value.toLowerCase();
            self.displayedGardens = self.jardins.filter(garden => self.isDisplayedGarden(event, garden));
        });

        // When map drag check for points in the bounds
        this.map?.map?.on('drag', function () {
            self.displayedGardens = self.jardins.filter(garden => self.isDisplayedGarden(null, garden));
            // Unselect the garden
            self.unselectJardin();
        });

        // When map zoom check for points in the bounds
        this.map?.map?.on('zoom', function () {
            self.displayedGardens = self.jardins.filter(garden => self.isDisplayedGarden(null, garden));
        });

        var closeButton = document.querySelector(this.options.HTMLButtonsSelectors.closeGardenButtonSelector);
        closeButton?.addEventListener("click", function () {
            self.unselectJardin();
            self.map.resetView();
            setTimeout(function () {
                self.displayedGardens = self.jardins.filter(garden => self.isDisplayedGarden(null, garden));
            }, 300);
        });

        var openGardenDetailsButton = document.querySelector(this.options.HTMLButtonsSelectors.openGardenDetailsButtonSelector);
        openGardenDetailsButton?.addEventListener("click", function () {
            // Open the garden details
            // var onGardenDetailsOpened = new CustomEvent("onGardenDetailsOpened", {
            //     detail: self.selectedJardin
            // });
            // console.log("Open Garden Details");
            // document.dispatchEvent(onGardenDetailsOpened);
            self.startH5PProcess();
        });

    }




    selectJardin(id: number) {
        if (this.state === AppState.MAP) {
            // Find the garden
            var garden = this.jardins.find(garden => garden.id === id);
            if (!garden) { return; }
            this.selectedJardin = garden ? garden : null;
            this.state = AppState.FOCUSED;
            this.map.zoomOnJardin(garden);
            var onGardenSelected = new CustomEvent("onGardenSelected", {
                detail: garden
            });
            document.dispatchEvent(onGardenSelected);
        } else if (this.state === AppState.FOCUSED) {
            this.startH5PProcess();
        }
    }
    unselectJardin() {
        this.selectedJardin = null;
        this.state = AppState.MAP;
        // this.map.resetView();
        document.dispatchEvent(backButtonEvent);
    }

    async startH5PProcess() {
        var self = this;
        // The h5p loading process look like this 
        // 1. Fade screen to black
        // 2. Check if there's a h5p id
        // 3. If there's a h5p id
        // 3.1 Lazy load the h5p
        // 4. Check if there's a video attached to the garden
        // 5. If there's a video attached to the garden
        // 5.1 Load the video
        // 5.2 Play the video when is loaded
        // 5.3 Show the video and Unfade the screen
        // 5.4 When the video is about to finish fade the screen to black
        // 6. Show the h5p and do size adjustments
        if (!this.selectedJardin?.h5p_id) {
            // If there's no h5p attached to the garden return
            return;
        }
        // Fade to black
        var fadeElement = document.querySelector(this.options.HTMLSelectors.fadeSelector) as HTMLElement;
        if (!fadeElement) { return; }
        fadeElement.setAttribute("data-fade", "in");

        // Load the h5p in the background
        if (this.selectedJardin?.h5p_id) {
            this.h5pManager = new HP5Manager(this.options.HTMLSelectors.h5pContainerSelector, this.selectedJardin.h5p_id as number);
        }

        // Show the video and Unfade the screen
        var videoModal = document.querySelector(self.options.HTMLSelectors.videoModalSelector) as HTMLElement;
        if (!videoModal) { return; }
        videoModal.hidden = false;
        await new Promise(resolve => setTimeout(resolve, 1000));
        // If there's a video attached to the garden
        if (this.selectedJardin?.videoUrl) {
            // Load the video
            var video = document.querySelector(this.options.HTMLSelectors.videoSelector) as HTMLVideoElement;
            video.src = this.selectedJardin.videoUrl;
            video.load();
            console.log("Load Video");
            videoModal.children[0].setAttribute("data-fade", "in");
            // Play the video when is loaded
            video.onloadeddata = function () {
                console.log("Play Video")
                fadeElement.setAttribute("data-fade", "out");
                video.play();
                // When the video is about to finish fade the screen to black
                setTimeout(function () {
                    fadeElement.setAttribute("data-fade", "in");
                    self.h5pManager?.showH5P();
                }, video.duration * 1000 - 1000);

                setTimeout(function () {
                    fadeElement.setAttribute("data-fade", "out");
                    videoModal.children[0].setAttribute("data-fade", "out");
                    videoModal.hidden = true;
                }, video.duration * 1000 + 1000);
            }
        } else if (this.selectedJardin?.h5p_id) {
            setTimeout(function () {
            }, 1000);
            // Wait until an ifram with "h5p-iframe-" + h5pID is created
            var interval = setInterval(function () {
                var iframe = document.querySelector("#h5p-iframe-" + self.selectedJardin?.h5p_id);
                if (iframe) {
                    clearInterval(interval);
                    fadeElement.setAttribute("data-fade", "out");
                    self.h5pManager?.showH5P();
                }
            }, 500);
        }




    }



}

export class JardiDebMap {
    public map: L.Map | undefined;
    // @ts-ignore
    private markers: L.MarkerClusterGroup;
    private jardinZoom: number;
    private mapServer: string;
    private baseLat: number;
    private baseLng: number;
    private baseZoom: number;
    private mapElement: HTMLElement;
    private defaultBounds: L.LatLngBounds | undefined;

    constructor(lat: number, lng: number, zoom = 10, jardinZoom = 14, mapElement: HTMLElement) {
        var self = this;
        this.jardinZoom = jardinZoom;
        this.baseLat = lat;
        this.baseLng = lng;
        this.baseZoom = zoom;
        this.mapElement = mapElement;
        this.mapServer = "";
        this.markers = new L.MarkerClusterGroup();
    }

    async initialize(url: string = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png') {
        this.mapServer = await this.testMapServer(url);
        this.initializeMap();
        console.log("Map initialized");
        this.initializeMapBackButton();
        console.log("Back button initialized");
        this.initalizeMapMakers();
        console.log("Markers initialized");
        this.events();
    }

    initializeMap() {
        let map = new L.Map(this.mapElement, { zoomSnap: 0.25 }).setView([this.baseLat, this.baseLng], this.baseZoom);
        this.map = map;
        new L.TileLayer(this.mapServer, {}).addTo(this.map);
        // this.map.scrollWheelZoom.disable();
        // this.map.dragging.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();
        this.map.touchZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.setMaxBounds(this.map.getBounds());
        // $(".leaflet-control-zoom").remove();
    }

    initalizeMapMakers() {
        const self = this;
        this.markers = new L.MarkerClusterGroup({
            iconCreateFunction: function (cluster: L.MarkerCluster) {
                var markers = cluster.getAllChildMarkers();
                var html = '<div class="circle">' + markers.length + '</div>';
                return L.divIcon({ html: html, className: 'mycluster', iconSize: L.point(32, 32) });
            },
            spiderfyOnMaxZoom: false, showCoverageOnHover: true, zoomToBoundsOnClick: false
        });
        this.markers.on('clusterclick', function (a: any) {
            // Zoom on the cluster
            self.map?.flyTo(a.latlng, self.jardinZoom);
            $(".leaflet-control-backbutton").removeClass("invisible");
            var visibleMarkers = a.layer.getAllChildMarkers();
            var onClusterZoom = new CustomEvent("onClusterZoom", { detail: visibleMarkers });
            document.dispatchEvent(onClusterZoom);
        });
        if (!this.map) {
            throw new Error("Map not initialized");
        }
        this.markers.addTo(this.map);
    }

    initializeMapBackButton() {
        const self = this;
        // Create a new Control for a back button
        // @ts-ignore
        L.Control.BackButton = L.Control.extend({
            onAdd: function (map: L.Map) {
                var backButton = createBackButton();
                var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control-backbutton leaflet-control invisible cursor-pointer');
                container.appendChild(backButton);
                container.onclick = function () {
                    // Make the back button disappear
                    $(".leaflet-control-backbutton").addClass("invisible");
                    // Fire a custom event
                    document.dispatchEvent(backButtonEvent);
                }
                return container;
            },
            onRemove: function (map: L.Map) {
                // Nothing to do here
            }
        });
        // @ts-ignore
        L.control.backButton = function (opts: any) {
            // @ts-ignore
            return new L.Control.BackButton(opts);
        }
        // @ts-ignore
        L.control.backButton({
            position: 'topleft'
        }).addTo(this.map);
    }

    async testMapServer(url: string) {
        let testUrl = url;
        // Replace the {z}/{x}/{y} by 0/0/0
        testUrl = testUrl.replace("{z}/{x}/{y}", "0/0/0");
        // Replace the {tileSize} by 512  
        testUrl = testUrl.replace("{tileSize}", "?vector256");
        // Check if the map server is available
        try {
            const response = await fetch(testUrl, {
                method: "HEAD"
            });
            if (response.ok) {
                return url;
            }
        } catch (error) {
            // console.error(error);

        }
        return "https://tile.openstreetmap.org/{z}/{x}/{y}.png";
    }

    events() {
        const self = this;

    }


    addMarker(lat: number, lng: number, name: string, clickCallback: () => void): L.Marker {
        let marker = L.marker([lat, lng]).bindTooltip(name, { permanent: true, direction: "right" }).on('click', clickCallback);
        this.showMarker(marker);
        return marker;
    }

    removeMarkers(): void {
        if (this.markers) {
            this.markers.clearLayers();
        }
    }

    showMarker(marker: L.Marker): void {
        this.markers.addLayer(marker);
    }

    removeMarker(marker: L.Marker): void {
        this.markers.removeLayer(marker);
    }

    zoomOnJardin(jardin: { lat: number, lng: number }): void {
        this.map?.flyTo([jardin.lat, jardin.lng], this.jardinZoom);
        $(".leaflet-control-backbutton").removeClass("invisible");
    }

    resetView() {
        this.map?.setView([this.baseLat, this.baseLng], this.baseZoom);
    }


}


function createBackButton() {
    let backButton = document.createElement('div');
    backButton.style.width = '30px';
    backButton.style.height = '30px';
    // Add icon in svg
    backButton.innerHTML = '<svg width="26px" height="26px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>'
    return backButton;
}



// function loadH5PScene(id: Number, mainDiv: HTMLElement) {
//     // Check if the div already exists
//     if (mainDiv.querySelector("#h5p-" + id)) {
//         return;
//     }
//     var div = document.createElement("div");
//     div.id = "h5p-" + id;
//     div.classList.add("absolute", "top-0", "bottom-0", "left-0", "right-0", "backdrop-blur-md", "bg-white/30", "z-[4999]", "min-w-full", "min-h-full", "items-center", "justify-center");
//     div.hidden = true;
//     mainDiv.appendChild(div);
//     lazyLoadH5P(id, (response) => {
//         // Remove the last char of the response
//         response = response.slice(0, -1);
//         div.innerHTML = response;
//         var script = div.querySelector("script")?.innerText;
//         eval(script || "");
//         // @ts-ignore
//         H5P.init(div);
//     });
// }

// function loadH5PMinigame(id: Number, mainDiv: HTMLElement) {
//     // Check if the div already exists
//     if (mainDiv.querySelector("#h5p-" + id)) {
//         return;
//     }
//     var div = document.createElement("div");
//     div.id = "h5p-" + id;
//     div.classList.add("absolute", "top-0", "bottom-0", "left-0", "right-0", "backdrop-blur-md", "bg-white/80", "z-[5000]", "w-auto", "h-auto");
//     mainDiv.appendChild(div);
//     lazyLoadH5P(id, (response) => {
//         // Remove the last char of the response
//         response = response.slice(0, -1);
//         div.innerHTML = response;
//         var script = div.querySelector("script")?.innerText;
//         eval(script || "");
//         // @ts-ignore
//         H5P.init(div);
//     });
// }
